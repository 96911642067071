<template>
    <div id="app">
        <CreateTasks />
    </div>
</template>

<script>
import CreateTasks from '@/components/Mapping/CreateTasks';

export default {
    components: {
        CreateTasks,
    }
}
</script>